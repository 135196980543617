<template>
  <v-row no-gutters>
    <v-col>
      <div id="data-list">
        <!-- app drawer -->

        <data-filter
          v-model="isFilterDataSidebarActive"
          @filter-data="filterData"
        >
        </data-filter>

        <v-card
          tile
          :flat="$vuetify.breakpoint.mdAndDown "
          :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
        >
          <v-toolbar
            v-if="!$vuetify.breakpoint.smAndDown"
            :color="$vuetify.breakpoint.mdAndDown ? '#3b355a' : 'transparent'"
            flat
            :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
          >
            <v-toolbar-title class="text-h6 font-weight-medium">
              <v-icon
                large
                left
              >
                {{ icons.mdiCrosshairs }}
              </v-icon>
              Loglar
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
              plain
              text
              small
            >
              <v-icon left>
                {{ icons.mdiDownload }}
              </v-icon> İndir
            </v-btn>
            <v-btn
              plain
              text
              small
              @click.stop="isFilterDataSidebarActive = !isFilterDataSidebarActive"
            >
              <v-badge
                v-if="isFilterNumber"
                overlap
                color="tertiary"
                dot
                offset-x="15"
                offset-y="5"
              >
                <v-icon left>
                  {{ icons.mdiFilter }}
                </v-icon>
              </v-badge>
              <v-icon
                v-else
                left
              >
                {{ icons.mdiFilter }}
              </v-icon>
              Filitre
            </v-btn>
          </v-toolbar>
          <v-expansion-panels
            v-else
            v-model="panel"
            flat
            tile
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="text-body-2 font-weight-medium">
                  <v-icon

                    left
                  >
                    {{ icons.mdiCrosshairs }}
                  </v-icon>
                  Kullanıcılar
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0">
                <v-divider></v-divider>
                <v-list

                  class="pa-0"
                  color="grey"
                >
                  <v-list-item @click.stop="isFilterDataSidebarActive = !isFilterDataSidebarActive">
                    <v-list-item-content>
                      <v-list-item-title>Filitre</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon :color="isFilterNumber ? 'tertiary' : null ">
                        {{ icons.mdiFilter }}
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- table -->
          <v-data-table
            v-model="selectedRows"
            :headers="tableColumns"
            :items="dataListTable"
            :options.sync="options"
            :items-per-page="15"
            fixed-header
            :height=" dataTableHeight"
            :server-items-length="totalDataListTable"
            :loading="loading"
          >
            <template #[`item.customer`]="{item}">
              <v-chip
                small
                label
                color="grey"
              >
                <span> {{ item.customer }}</span>
              </v-chip>
            </template>

            <template #[`item.datetime`]="{item}">
              <span class="text--secondary text-caption"> {{ item.datetime.substring(0,16) }}</span>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
import {
  mdiCamera,
  mdiCameraOff,
  mdiClose,
  mdiCrosshairs,
  mdiDelete,
  mdiDownload,
  mdiEye,
  mdiFilter,
  mdiFilterOff,
  mdiPlusThick,
  mdiSend,
  mdiSquareEditOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import Vue from 'vue'
import dataFilter from './DataFilter.vue'

export default {
  components: {
    dataFilter,
  },
  setup() {
    const isFilterNumber = ref(0)
    const isFilterDataSidebarActive = ref(false)
    const dataListTable = ref([])
    const dataItem = ref({})

    const tableColumns = [
      {
        text: 'ID',
        value: 'id',
        width: '90px',
        sortable: false,
      },
      {
        text: 'Kullanıcı',
        value: '',
        width: '200px',
      },
      {
        text: 'Başlık',
        value: '',
        width: '250px',
      },

      {
        text: 'Açıklama',
        value: '',
      },

      {
        text: 'İşlem Tarihi',
        value: 'datetime',

        width: '200px',
      },
    ]

    const searchQuery = ref('')

    const totalDataListTable = ref(0)
    const loading = ref(false)
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })
    const dataTotalLocal = ref([])
    const selectedRows = ref([])
    const fetchDatas = () => {
      const query = {
        options: options.value,
        't.id': idFilter.value,
      }
      const method = 'getPaymentActions'
      store
        .dispatch('fetchMethod', { query, method })
        .then(response => {
          dataListTable.value = response.response.result
          totalDataListTable.value = Number(response.response.pagination.totalRec)
          loading.value = false
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }
    const statusFind = (arr, value, select) => store.state.tanimlar[arr].find(item => item.value === value)[select]

    const idFilter = ref(null)
    const filterData = item => {
      idFilter.value = item.id || null

      let i = 0
      // eslint-disable-next-line no-restricted-syntax, no-unused-vars
      for (const [key, value] of Object.entries(item)) {
        if (value) {
          i += 1
        }
      }
      isFilterNumber.value = i
    }
    watch([searchQuery, idFilter, options], () => {
      loading.value = true
      selectedRows.value = []
      fetchDatas()
    })

    return {
      statusFind,
      dataItem,
      isFilterNumber,
      filterData,
      dataListTable,
      tableColumns,
      searchQuery,
      idFilter,
      totalDataListTable,
      loading,
      options,
      dataTotalLocal,
      isFilterDataSidebarActive,
      selectedRows,
      fetchDatas,

      // icons
      icons: {
        mdiCamera,
        mdiDelete,
        mdiCameraOff,
        mdiCrosshairs,
        mdiEye,
        mdiFilter,
        mdiFilterOff,
        mdiPlusThick,
        mdiSquareEditOutline,
        mdiClose,
        mdiDownload,
        mdiSend,
      },
    }
  },
  data() {
    return {
      panel: null,
      date: null,
      modal: false,
      filterStatus: false,
      dataTableHeight: '',
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 48 : 87,
        footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
      },
    }
  },

  watch: {
    panel(val) {
      if (val === 0) {
        setTimeout(() => {
          this.height.top = 250
          this.onResize()
        }, 300)
      } else {
        this.height.top = 48
        this.onResize()
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
